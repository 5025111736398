<template>
  <!-- 404 page  , page not found , used in routing error  -->
  <div>
    <div class="d-flex justify-content-center align-items-center">
      <div class="text-center">
        <div class="py-5">
          <svg width="280" height="340" viewBox="0 0 231 280">
            <g>
              <path
                style=""
                fill="#EF4923"
                d="M230.9,123.8l-5.1-13.4c0,0,0-0.1-0.1-0.1c-0.1-0.4-0.5-0.8-0.9-0.8h-53.4l-54.2-96.7V4.2h-0.1 C117,1.8,115,0,112.6,0c-2.4,0-4.4,1.8-4.7,4.2h-0.1v0.6c0,0,0,0,0,0s0,0,0,0V13l-54.1,96.6H1c-0.5,0-1,0.4-1,1v163.2 c0,0.4,0.3,0.8,0.7,0.9c0,0,0.1,0.1,0.1,0.1l12.7,5.2c0.1,0,0.2,0.1,0.4,0.1H230c0.5,0,1-0.4,1-1V124.2 C231,124.1,231,123.9,230.9,123.8z M109.8,4.8c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8c0,1.6-1.3,2.8-2.8,2.8 C111,7.6,109.8,6.4,109.8,4.8L109.8,4.8z M109.8,8.6c0.8,0.6,1.8,1,2.8,1c1.1,0,2-0.4,2.8-1v9.1c0,1.2-1,2.3-2.3,2.3H112 c-1.2,0-2.3-1-2.3-2.3V8.6z M107.8,16.9v0.8c0,2.3,1.9,4.2,4.2,4.2h1.1c2.3,0,4.2-1.9,4.2-4.2v-0.9l51.9,92.8H55.9L107.8,16.9z  M1.9,111.5h50.7l-2.3,4.1c-0.4-0.1-0.7-0.2-1.1-0.2c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4 c0-1.4-0.6-2.6-1.6-3.4l2.8-5h115.5l2.9,5.1c-0.9,0.8-1.5,2-1.5,3.3c0,2.4,2,4.4,4.4,4.4c2.4,0,4.4-2,4.4-4.4s-2-4.4-4.4-4.4 c-0.4,0-0.9,0.1-1.3,0.2l-2.3-4.2h51.3V138H1.9V111.5z M48.7,120.5c0.2,0.1,0.3,0.1,0.5,0.1c0.3,0,0.7-0.2,0.8-0.5l1-1.8 c0.4,0.4,0.6,1,0.6,1.6c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c0,0,0.1,0,0.1,0l-1,1.7 C48.1,119.6,48.3,120.2,48.7,120.5z M176.9,119.1l-1-1.7c0.1,0,0.2,0,0.3,0c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5 s-2.5-1.1-2.5-2.5c0-0.6,0.2-1.1,0.5-1.5l0.9,1.7c0.2,0.3,0.5,0.5,0.8,0.5c0.2,0,0.3,0,0.5-0.1C177,120.2,177.1,119.6,176.9,119.1z  M140.1,207.9l-0.4-6.3l20.9-33h10v31.2h5.7v8h-5.7v10.8h-9.9v-10.8H140.1z M129.1,188.8v9.2c0,6.9-1.4,12.2-4.3,15.9 c-2.9,3.7-7.1,5.5-12.6,5.5c-5.5,0-9.6-1.8-12.5-5.4c-2.9-3.6-4.4-8.8-4.4-15.5v-9.2c0-7,1.5-12.3,4.4-15.9 c2.9-3.6,7.1-5.4,12.5-5.4c5.5,0,9.6,1.8,12.5,5.4C127.6,176.9,129.1,182,129.1,188.8z M48.6,207.9l-0.4-6.3l20.9-33h10v31.2h5.7v8 h-5.7v10.8h-9.9v-10.8H48.6z M1.9,248.9h221.9v23.9H1.9V248.9z M229.1,278.1h-215l-8.2-3.3h219c0.5,0,1-0.4,1-1v-24.3l3.2,6.2 V278.1z M229.1,144.3l-3.2-5.8v-22.6l3.2,8.5V144.3z"
              ></path>
              <polygon
                style=""
                fill="#EF4923"
                points="160.6,182.2 159.9,183.3 149.5,199.8 160.6,199.8"
              ></polygon>
              <polygon
                style=""
                fill="#EF4923"
                points="69.2,182.2 68.5,183.3 58.1,199.8 69.2,199.8"
              ></polygon>
              <path
                style=""
                fill="#EF4923"
                d="M112.2,175.9c-2.3,0-4.1,0.9-5.2,2.7s-1.7,4.6-1.8,8.5v12.2c0,4.1,0.6,7.1,1.7,9.1c1.1,2,2.9,3,5.3,3 c2.4,0,4.2-1,5.3-2.9s1.6-4.8,1.7-8.7v-11.9c0-4.2-0.6-7.2-1.7-9.1C116.3,176.9,114.6,175.9,112.2,175.9z"
              ></path>
              <path
                style=""
                fill="#EF4923"
                d="M212.4,121.2c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6C216,122.8,214.4,121.2,212.4,121.2z  M212.4,126.4c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6S213.3,126.4,212.4,126.4z"
              ></path>
              <path
                style=""
                fill="#EF4923"
                d="M201.4,121.2c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6C205,122.8,203.4,121.2,201.4,121.2z  M201.4,126.4c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6s1.6,0.7,1.6,1.6S202.3,126.4,201.4,126.4z"
              ></path>
              <path
                style=""
                fill="#EF4923"
                d="M20.3,119.9h-9.7c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h9.7c0.5,0,1-0.4,1-1C21.2,120.3,20.8,119.9,20.3,119.9z"
              ></path>
              <path
                style=""
                fill="#EF4923"
                d="M20.3,123.8h-9.7c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1h9.7c0.5,0,1-0.4,1-1C21.2,124.2,20.8,123.8,20.3,123.8z"
              ></path>
              <path
                style=""
                fill="#EF4923"
                d="M20.3,127.7h-9.7c-0.5,0-1,0.4-1,1s0.4,1,1,1h9.7c0.5,0,1-0.4,1-1S20.8,127.7,20.3,127.7z"
              ></path>
            </g>
          </svg>
        </div>
        <h4 class="my-3 text-danger">
          {{ $t("home.pageNoteFound1") }}
        </h4>
        <div class="mt-0 mb-4">
          <b-button variant="outline-danger" to="/">
            {{ $t("home.home") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "404 page  , page not found",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: `${localStorage.getItem("lang")}`,
      amp: true,
    },
  },
};
</script>

<style></style>
